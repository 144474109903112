import { Link } from "react-router-dom";
import "./main.scss";
import "./homeResponse.scss";
import Share from "../../assets/icons/share.png";
import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { useState, useEffect } from "react";
import { request } from "../../api/request";
import parse from "html-react-parser";
import NoImage from "../../assets/images/download.png";
import Telegram from "../../assets/icons/telegram.png";
import Facebook from "../../assets/icons/facebook-app-symbol.png";
import Instagram from "../../assets/icons/instagram.png";
import Close from "../../assets/images/close.png";
import { useSelector } from "react-redux";
import CountUp from "react-countup";
import { Helmet } from "react-helmet";

import { CarouselOne, Card, PieChart, Helm } from "../../components/index";


import {
  FacebookShareButton,InstapaperShareButton,
  TelegramShareButton
}from 'react-share'


const Home = () => {

  const [active, setActive] = useState(false);
  const lang = useSelector((state) => state.data.lang);
  const water_saving = useSelector(state => state.data.water)
  const bannerNews = useSelector(state => state.data.post)

  const [chartData, setChartData] = useState({
    isFetched: false,
    data: {},
    error: null,
  });

  const [homeapi, sethomeapi] = useState({
    isFetched: false,
    data: {},
    error: null,
  });


  const [suvCard, setSuvCard] = useState({
    isFetched: false,
    data: {},
    error: null,
  });


  const [final, setFinal] = useState({
    isFetched: false,
    data: {},
    error: null,
  });

 

  useEffect(() => {
    request
      .get(
        `/content/all/post?lang=${lang === null ? "uz" : lang}&on_home_page=1&sortby=created_on&sort=desc`,
        {
          headers: {
            "api-token": "p12378",
          },
        }
      )

      .then(function (res) {
        sethomeapi({
          isFetched: true,
          data: res.data.data,
          error: false,
        });
      })

      .catch(function (err) {
        sethomeapi({
          isFetched: true,
          data: [],
          error: err,
        });
      });
    request
      .get(`/content/all/info?lang=${lang === null ? "uz" : lang}`, {
        headers: {
          "api-token": "p12378",
        },
      })

      .then(function (res) {
        setSuvCard({
          isFetched: true,
          data: res.data.data,
          error: false,
        });
      })

      .catch(function (err) {
        setSuvCard({
          isFetched: true,
          data: {},
          error: err,
        });
      });

    request
      .get(
        `content/all/useful?on_home_page=1&lang=${lang === null ? "uz" : lang}`,
        {
          headers: {
            "api-token": "p12378",
          },
        }
      )
      .then(function (res) {
        setFinal({
          isFetched: true,
          data: res.data.data,
          error: false,
        });
      })
      .catch(function (err) {
        setFinal({
          isFetched: true,
          data: [],
          error: err,
        });
      });

    request
      .get(`/chart/all?lang=${lang === 'null' ? 'uz' : lang}`, {
        headers: {
          "api-token": "p12378",
        },
      })

      .then((res) => {
        setChartData({
          isFetched: true,
          data: res.data.data,
          error: false,
        });
      })

      .catch((err) => {
        setChartData({
          isFetched: true,
          data: [],
          error: err,
        });
      });

      window.scrollTo(0,0)

  }, [lang]);
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoPlay: true,
    autoPlaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsTwo = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoPlay: true,
    autoPlaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsThree = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoPlay: true,
    autoPlaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsCount = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    rows: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="home-wrapper">
      <Helm id={"24"} />
      
      <div className="hero-page container">
        <div className="hero-main"
             style={{backgroundImage: "url("+process.env.REACT_APP_ASSETS_URL+"/uploads/pages/pagecover.png"}}>
                  <div className="overlay"></div>
                  <h1 className="hero-title">Bosh sahifa</h1>
        </div>
    {bannerNews && bannerNews.status === 1 ? (
          <div className="inner-hero">
            <Helmet>
              <meta property="og:title" content="Suv xo'jaligi tashkilotinig rasmiy veb sayti"/>
              <meta property="og:description" content={bannerNews && bannerNews.data[0].extra_desc}/>
              <meta property="og:image" content={bannerNews && bannerNews.data[0].image !== ""
                  ? bannerNews.data[0].image
                  : NoImage}/>
              <meta property="og:url" content={window.location.pathname}/>
            </Helmet>
          <div className="left">
            <img
              src={
                bannerNews && bannerNews.data[0].image !== ""
                  ? bannerNews.data[0].image
                  : NoImage
              }
              alt=""
            />
          </div>
          
          <div className="right">
            <div className="events">
              <span className="event-link">
                {
                  bannerNews && bannerNews.data && bannerNews.data.category_title && bannerNews.data.category_title 
                }
              </span>
              <div className="share-icons">
                <button
                  className="share-btn"
                  onClick={() => setActive(!active)}
                >
                  <img src={active ? Close : Share} alt="" />
                </button>

                <div className={`social-icons ${active ? "active" : ""}`}>
                  <ul>
                    <li>
                      <FacebookShareButton
                        quote="share-to-facebook"
                        className={`${active ? "active" : ""}`}
                        url={window.location.href}
                      >
                        <img src={Facebook} alt="" />
                      </FacebookShareButton>
                    </li>
                    <li>
                      <InstapaperShareButton
                        quote="share-to-instagram"
                        className={`${active ? "active" : ""}`}
                        url={String(window.location)}
                      >
                        <img src={Instagram} alt="" />
                      </InstapaperShareButton>
                    </li>
                    <li>
                      <TelegramShareButton
                        quote="share-to-telegram"
                        className={`${active ? "active" : ""}`}
                        url={String(window.location)}
                      >
                        <img src={Telegram} alt="" />
                      </TelegramShareButton>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="holder">
                <>
                    <div className="infoAsadbek">
                      <Link
                          to={`/single/post/${bannerNews && bannerNews.data[0].id}`}
                          className="holder-name"
                      >
                        {bannerNews && bannerNews.data[0].title}
                      </Link>
                      <div className="holder-title">
                        {bannerNews && bannerNews.data[0].extra_desc}
                      </div>
                      <span className="date">
                      {bannerNews && bannerNews.data[0].created_on}
                    </span>
                    </div>
                    <div style={{textAlign:"center"}}>
                      <Link to="/tomchi">
                        <img src="/static/media/TOMCHILOGO.c9dfd6e7.png" alt="" width="200"/>
                      </Link>
                      <hr/>
                      <a
                          href={process.env.REACT_APP_ASSETS_URL+"/uploads/projects/sfr/sfr_dasturi.zip"}
                          download
                          style={{
                            fontSize:"18px"
                          }}
                          target="_blank">
                        <img src="/img/sfr.png" width="200" alt=""/>
                        {/*SFR dasturini yuklab olish*/}
                        </a>
                    </div>
                  </>
            </div>
          </div>
        </div>
    ) : null}
      </div>

      <div className="carousel-section_one">
        <div className="inner container">
          <Slider {...settings}>
            {homeapi.isFetched ? (
              homeapi.data &&
              homeapi.data
                .filter((el) => el.post_main_item !== "1")
                .map((item, index) => (
                  <div className="carousel-items" key={index}>
                    <CarouselOne
                      title={item.title}
                      description={item.extra_desc}
                      date={item.created_on}
                      id={item.id}
                      image={item.image}
                      category={
                        item.category_title && item.category_title[0].title
                      }
                    />
                  </div>
                ))
            ) : (
              <h1>Loading ...</h1>
            )}
          </Slider>
        </div>
      </div>

      <div className="analys-information">
        <div className="inner container">
          <div className="holder">
            <h2>
              {
                lang === 'uz' ? 'AXBOROT-TAHLIL MA\'LUMOTLARI' : lang === 'ru' ? 'ИНФОРМАЦИОННЫЕ ДАННЫЕ АНАЛИЗА' : lang === 'en' ? 'INFORMATION ANALYSIS DATA' : ''
              }
            </h2>
          </div>

          {
            suvCard.isFetched ? (
              suvCard.data && suvCard.data.length > 8 ?(
                    <Slider {...settingsCount}>
                      {
                        suvCard.data && suvCard.data.map((item, index)=>(
                      <div className="info-card" key={index}>
                        <div className="info-name">
                          <h3>{item.title}</h3>
                          <div className="numbers">
                            <div className="image-icon">
                              <img src={item.image} alt="" />
                            </div>
                            <span>
                              <CountUp
                                start={0}
                                end={parseInt(item.extra_text)}
                                duration={5}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                        ))
                      }
                    </Slider>
              ):(
                <div className="informations">
                  {
                    suvCard && suvCard.data &&
                    suvCard?.data.map((item, index) => (
                      <div className="info-card" key={index}>
                        <div className="info-name">
                          <h3>{item.title}</h3>
                          <div className="numbers">
                            <div className="image-icon">
                              <img src={item.image} alt="" />
                            </div>
                            <span>
                              <CountUp
                                start={0}
                                end={parseInt(item.extra_text)}
                                duration={5}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  }
               </div>
              )
            ):(
              null
            )
          }
        </div>
      </div>

      <div className="technologies">
        <div className="inner container">
          <div className="block-heading">
            <div className="left-side">
              <h2>
                {
                  lang === 'uz' ? 'SUV TEJASH TEXNOLOGIYALARI' : lang === 'ru' ? 'ВОДОСБЕРЕГАЮЩИЕ ТЕХНОЛОГИИ' : lang === 'en' ? 'WATER SAVING TECHNOLOGIES' : ''
                }
              </h2>
            </div>
            <div className="right-side">
              <Link to="/waterSaving" className="primary_btn">
                {lang === "uz"
                  ? "Batafsil ko\'rish"
                  : lang === "ru"
                  ? "Узнать больше"
                  : lang === "en"
                  ? "See more"
                  : " "}
              </Link>
            </div>
          </div>

          <div className="block-infos">
            {
             water_saving && water_saving.status  === 1 ? (
                water_saving && water_saving.data.slice(0, 4).map((item, index) => (
                  <div className="cards" key={index}>
                    <Card
                      theme={item.title}
                      title={item.title}
                      description={item.extra_text}
                      date={item.created_on}
                      id={item.id}
                      image={item.image}
                    />
                </div>
              ))
            ) : (
              <h1>loading</h1>
            )
            
            }
          </div>

          <div className="charts-informations">
            <div className="chart-holder">
              <h1>
                {lang === "uz"
                  ? "Diagrammalar"
                  : lang === "ru"
                  ? "диаграммы"
                  : lang === "en"
                  ? "diagrams"
                  : ""}
              </h1>
            </div>

            <div className="charts-diagram">
              <Slider {...settingsThree}>
                {chartData.isFetched ? (
                  chartData.data &&
                  chartData.data.map((el) => (
                    <div className="chart-diagram-cards" key={el.id}>
                    <h1>{el.title}</h1>
                      <PieChart
                        labelData={el.labels}
                        datas={el.datasets.data}
                        color={el.datasets.color}
                        type={el.key}
                        id={el.id}
                        description={el.description}
                      />
                    </div>
                  ))
                ) : (
                  <h1>Loading ...</h1>
                )}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div className="usefull-links">
        <div className="inner container">
          <div className="link-holder">
            <h1>
              {
                lang === 'uz' ? 'FOYDALI HAVOLALAR' : lang === 'ru' ? 'ПОЛЕЗНЫЕ ССЫЛКИ': lang === 'en' ? 'USEFUL LINKS': ''
              }
            </h1>
          </div>

          {final.isFetched ? (
            final.data.length <= 4 ? (
              <div className="flex-blocks">
                {final.data.map((item, index) => (
                  <div className="link-card-wraper" key={index}>
                    <div className="link-card">
                      <img src={item.image} alt="" />
                      <div>
                        <h3 className="card-name">{item.title}</h3>
                        <div className="useful-desc">
                          {parse(`${item.description}`)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <Slider {...settingsTwo}>
                {final.data.map((item, index) => (
                  <div className="link-card-wraper" key={index}>
                    <div className="link-card">
                      <img src={item.image} alt="" />
                      <div>
                        <h3 className="card-name">{item.title}</h3>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            )
          ) : (
            <h1>Loading ...</h1>
          )}
        </div>
      </div>
    </div>
  );
};
export default Home;
