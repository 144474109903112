import React from "react";
import styled from "styled-components";

const Table = styled.table`
  margin: 20px 0;
  thead {
    th {
      border: 1px solid black;
      font-size: 20px;
      padding: 10px;
    }
  }
  tbody {
    td {
      border: 1px solid black;
      padding: 10px;
      font-size: 18px;
      h5 {
        font-weight: bold;
        font-size: 20px;
      }
      p {
        font-size: 18px;
      }
    }
  }
`;
const Wrapper = styled.div`
  .top-img {
    display: flex;
    justify-content: space-between;
  }
  img {
    width: 100%;
  }
  .one {
    width: 20%;
    h1 {
      font-size: 12px;
    }
  }
  .two {
    width: 30%;
    h1 {
      font-size: 20px;
      text-align: center;
    }
  }
  .three {
    width: 20%;
    h1 {
      font-size: 12px;
    }
  }
  .coordination_Downloads {
    padding-bottom: 20px;
    a {
      border: none;
      padding: 5px 30px 5px 22px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      color: #3987f3;
      background: rgba(57, 135, 243, 0.2);
      border-radius: 10px;
      cursor: pointer;
      display: inline-block;
      margin: 0 5px 5px 0;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 11.67px;
        height: 10.5px;
        background-size: contain;
        right: 6px;
        top: 50%;
        transform: translateY(-56%);
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAOCAYAAADwikbvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEUSURBVHgBpVJLUoNAEO1uRi0XWvEGHIElriQn8AgmJ0iypNxguWCrJzA30BuwlGWOwA1IycLSDNNpJgn5VSCp9OZRvHmv34MBaJggyjtNPB0i/Nci+LtSuR8XwcliUOxaNEs8SXzEnCXG3Rd+nLuoHY8VdBDwgw33EWHKWE7S8C7bPKv27JgiUPhEjBNGBkIaCHoEF2Nh+9AYG00kDlklsF4WOTMwe2mNXUcHJxHatUZQdncjW3F1EeR/JtLtPX2+HW8bqCGDftsU+vFPTyoNLv91d9XZI6IHwVq8FAz3tiE+spyvY9/HRVIZyNf9gpYRYVBV+Q5vFpu1gyNVmk8hetA6PNVOOao3r/v8um3SNLzOVs9znwloNQHLRowAAAAASUVORK5CYII=);
      }
    }
  }
  table{
    tr{
      td:first-child{
        width: 15%;
        padding: 5px;
        text-align: center;
        vertical-align: middle;
        font-weight: 700;
      }
      td{
        padding: 5px;
      }
    }
  }
`;

export default function Eview1() {
    return (
        <Wrapper>
            <div className="top-img">
                <div className="one">
                    <img
                        src="https://careerdevelopmentroundtable.com/wp-content/uploads/2019/09/logo-confederation-suisse-e1568383635140.jpg"
                        alt=""
                    />
                    <h1>Swiss agency for development and cooperation SDK</h1>
                </div>
                <div className="two">
                    <img
                        src={process.env.REACT_APP_ASSETS_URL+"/uploads/iarclogo.gif "}
                        alt=""
                    />
                </div>
                <div className="three">
                    <img src="https://water.gov.uz/images/logo2.png" alt="" />
                    <h1>Ministry of water resources of the Republic of Uzbekistan</h1>
                </div>
            </div>
            <br/><br/>
            <div>
                <h2 style={{"textAlign":"center"}}>
                    MEETING OF SECTORAL WORKING GROUP FOR WATER RESOURCES MANAGEMENT
                </h2>
            </div>

            <div style={{"marginTop":"15px","textAlign":"justify"}}>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;On May 31, 2022, a meeting of the Working Group for Water Resources Management was held in Tashkent. Such meetings, that have become a platform for dialogue between partners in the development of water resources, are being regularly held since 2015.</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;The Group includes the Ministry of Water Resources of the Republic of Uzbekistan, relevant ministries and departments, international development partners operating in the country. Its main task is to establish an effective dialogue between development organizations and the state to ensure effective cooperation for the development of water management and coordination of activities in water sector.</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;At the meeting, Mr. Kamalov, the head of the Center for the implementation of foreign investment projects in the water sector, informed about the reforms carried out within the framework of the Concept and the Strategy for the development of the water sector.</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;- We are ready to support the reforms carried out by the Government of Uzbekistan, - said Ms. Rahel Boesch, Head of the Cooperation of the Embassy of Switzerland in Uzbekistan.  Pursuing this goal, the representatives of various ministries, departments, international development organizations have gathered here today”.</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;The participants of the meeting discussed the issues on the agenda in order to coordinate grant projects implemented in the water sector of the Republic, to prevent duplication of project activities and to increase efficiency.</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;The event was organized with the support of the Swiss Agency for Development and Cooperation.</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;</p>
                <br/><br/>
            </div>
            <div>
                <h2>Files for download</h2>
                <br />
            </div>
            <div className="coordination_Downloads">
                <a
                    className="btn btn-primary"
                    href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/01iarcwsdm-ppt-eng.pptx"}
                >
                    WSRC WSDM PPT (Eng)
                </a>
                <a href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/02sdcnwrmpstatus-eng.pptx"}>
                    SDC NWRMP status (Eng){" "}
                </a>
                <a href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/03swissemnassypppforwatersectordonormeeting3.pptx"}>
                    Swiss Embassy PPT WSDM
                </a>
                <a href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/04gizpptwater-sector-donor-meeting.pptx"}>
                    GIZ PPT WSDM 4 <i className="fa fa-download"></i>
                </a>
                <a href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/05afd-for-water-sector-donor-meeting.pptx"}>
                    AFD WSDM
                </a>
                <a href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/06eu-ppp-for-water-sector-donor-meeting-220530.pptx"}>
                    EU PPT WSDM{" "}
                </a>
                <a
                    target="_blank"
                    href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/07adb-water-sector-donor-meeting-final.pdf"}
                >
                    ADB WSDM FINAL{" "}
                </a>
                <a href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/agenda.docx"}>Agenda (uzb/eng)</a>
                <a href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/lopwsdm.docx"}>LOP_WSDPCM</a>
                <a href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/final-to-circulate-water-sector-development-partners-meeting-minutes31-may-2022feedback-brx.docx"}>Meeting minutes</a>
            </div>

            <div><h2>Video from WSDM</h2></div>
            <br/>
            <div>

                <iframe width="100%" height="400" src="https://www.youtube.com/embed/Q3HOUijuAQw" title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
            <br/><br/>
            <div><h2>Projects of development partners</h2></div>
            <br/>
            <ul>
                <li>
                    <h3>
                        <a target="_blank" href="https://projects.worldbank.org/en/projects-operations/projects-list?lang=en&countrycode_exact=UZ&os=0">
                            World Bank Uzbekistan Projects
                        </a>
                    </h3>
                </li>
                <li>
                    <h3>
                        <a target="_blank" href="https://www.adb.org/projects/country/uzb">
                            ADB Uzbekistan Projects
                        </a>
                    </h3>
                </li>
                <li>
                    <h3>
                        <a target="_blank" href="https://www.undp.org/uzbekistan/projects">
                            UNDP Uzbekiistan Projects
                        </a>
                    </h3>
                </li>
                <li>
                    <h3>
                        <a target="_blank" href="https://www.eeas.europa.eu/filter-page/projects_en?fulltext=Uzbekistan&start_from=&start_to=">
                            EU Central Asia Projects
                        </a>
                    </h3>
                </li>
                <li>
                    <h3>
                        <a target="_blank" href="https://www.eda.admin.ch/deza/en/home/countries/central-asia.html">
                            SDC Central Asia Project Data base
                        </a>
                    </h3>
                </li>
                <li>
                    <h3>
                        <a target="_blank" href="https://www.giz.de/en/worldwide/364.html">
                            GIZ Uzbekistan Projects and Programs
                        </a>
                    </h3>
                </li>
                <li>
                    <h3>
                        <a target="_blank" href="https://www.afd.fr/en/page-region-pays/uzbekistan">
                            AFD projects in Uzbekistan
                        </a>
                    </h3>
                </li>
            </ul>
            <br/><br/><br/>
        </Wrapper>
    );
}
