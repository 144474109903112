import React from "react";
import styled from "styled-components";

const Table = styled.table`
  margin: 20px 0;
  thead {
    th {
      border: 1px solid black;
      font-size: 20px;
      padding: 10px;
    }
  }
  tbody {
    td {
      border: 1px solid black;
      padding: 10px;
      font-size: 18px;
      h5 {
        font-weight: bold;
        font-size: 20px;
      }
      p {
        font-size: 18px;
      }
    }
  }
`;
const Wrapper = styled.div`
  .top-img {
    display: flex;
    justify-content: space-between;
  }
  img {
    width: 100%;
  }
  .one {
    width: 20%;
    h1 {
      font-size: 12px;
    }
  }
  .two {
    width: 30%;
    h1 {
      font-size: 20px;
      text-align: center;
    }
  }
  .three {
    width: 20%;
    h1 {
      font-size: 12px;
    }
  }
  .coordination_Downloads {
    padding-bottom: 20px;
    a {
      border: none;
      padding: 5px 30px 5px 22px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      color: #3987f3;
      background: rgba(57, 135, 243, 0.2);
      border-radius: 10px;
      cursor: pointer;
      display: inline-block;
      margin: 0 5px 5px 0;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 11.67px;
        height: 10.5px;
        background-size: contain;
        right: 6px;
        top: 50%;
        transform: translateY(-56%);
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAOCAYAAADwikbvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEUSURBVHgBpVJLUoNAEO1uRi0XWvEGHIElriQn8AgmJ0iypNxguWCrJzA30BuwlGWOwA1IycLSDNNpJgn5VSCp9OZRvHmv34MBaJggyjtNPB0i/Nci+LtSuR8XwcliUOxaNEs8SXzEnCXG3Rd+nLuoHY8VdBDwgw33EWHKWE7S8C7bPKv27JgiUPhEjBNGBkIaCHoEF2Nh+9AYG00kDlklsF4WOTMwe2mNXUcHJxHatUZQdncjW3F1EeR/JtLtPX2+HW8bqCGDftsU+vFPTyoNLv91d9XZI6IHwVq8FAz3tiE+spyvY9/HRVIZyNf9gpYRYVBV+Q5vFpu1gyNVmk8hetA6PNVOOao3r/v8um3SNLzOVs9znwloNQHLRowAAAAASUVORK5CYII=);
      }
    }
  }
  table{
    tr{
      td:first-child{
        width: 15%;
        padding: 5px;
        text-align: center;
        vertical-align: middle;
        font-weight: 700;
      }
      td{
        padding: 5px;
      }
    }
  }
`;

export default function Uview1() {
    return (
        <Wrapper>
            <div className="top-img">
                <div className="one">
                    <img
                        src="https://careerdevelopmentroundtable.com/wp-content/uploads/2019/09/logo-confederation-suisse-e1568383635140.jpg"
                        alt=""
                    />
                    <h1>Swiss agency for development and cooperation SDC</h1>
                </div>
                <div className="two">
                    <img
                        src={process.env.REACT_APP_ASSETS_URL+"/uploads/iarclogo.gif "}
                        alt=""
                    />
                </div>
                <div className="three">
                    <img src="https://water.gov.uz/images/logo2.png" alt="" />
                    <h1>O'zbekiston Respublikasi Suv Xo'jaligi Vazirligi</h1>
                </div>
            </div>
            <br/><br/>
            <div>
                <h2 style={{"textAlign":"center"}}>
                    SUV XO‘JALIGINI RIVOJLANTIRISH BO‘YICHA HAMKORLAR UCHRASHDI
                </h2>
            </div>

            <div style={{"marginTop":"15px","textAlign":"justify"}}>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;2022 yil 31 may kuni poytaxtimizda Suv xo‘jaligini rivojlantirish bo‘yicha hamkorlarni muvofiqlashtirish Tarmoq ishchi guruhining yig‘ilishi bo‘lib o‘tdi.</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;Mamlakatimiz suv xo‘jaligi sohasini rivojlantirishda xalqaro va donor tashkilotlar bilan yaqin hamkorlik yo‘lga qo‘yilgan. Bu borada ko‘plab g‘oya va loyihalar amalga oshirib kelinmoqda.</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;Suv xo‘jaligini rivojlantirish bo‘yicha hamkorlarning o‘zaro muloqot maydoniga aylangan Tarmoq ishchi guruhining yig‘ilishi 2015 yildan buyon muntazam o‘tkazib kelinadi. </p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;Uning tarkibiga O‘zbekiston Respublikasi Suv xo‘jaligi vazirligi, tegishli vazirlik va idoralar, respublikamizda faoliyat yuritayotgan xalqaro va donor tashkilotlar kiritilgan. Asosiy vazifasi suv xo‘jaligi sohasini rivojlantirish uchun samarali hamkorlikni ta’minlash hamda faoliyatni muvofiqlashtirish uchun donorlar va hukumat o‘rtasida muloqotlarni yo‘lga qo‘yishdan iborat.</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;Yig‘ilishda Suv xo‘jaligida xorijiy investitsiya loyihalarini amalga oshirish markazi rahbari B.Kamalov suv xo‘jaligini rivojlantirish Kontseptsiyasi va Strategiyasi doirasida olib borilayotgan islohotlar haqida ma’lumot berib o‘tdi.</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;-O‘zbekiston hukumati tomonidan olib borilayotgan islohotlarga ko‘makdosh bo‘lishga tayyormiz, - dedi O‘zbekistondagi Shveytsariya elchixonasining hamkorlik bo‘limi boshlig‘i Rahel Byosh. – Shu maqsadda bugun bu erga turli vazirlik, idoralar hamda xalqaro va donor tashkilotlar vakillari to‘plangan.</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;Respublikamiz suv xo‘jaligida amalga oshirilayotgan grant loyihalarni muvofiqlashtirish, loyihalar doirasidagi tadbirlar  bir-birini takrorlashining oldini olish, samaradorlikni oshirish maqsadida o‘tkazilgan yig‘ilish ishtirokchilari kun tartibidagi masalalarni muhokama qildilar</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;Tadbir Shveytsariya taraqqiyot va hamkorlik agentligi ko‘magida tashkil etildi. </p>
                <br/><br/>
            </div>


            <div>
                <h2>Yuklab olish uchun hujjatlar</h2>
                <br />
            </div>
            <div className="coordination_Downloads">
                <a
                    className="btn btn-primary"
                    href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/01iarcwsdm-ppt-eng.pptx"}
                >
                    IARC WSDM PPT (Eng)
                </a>
                <a href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/02sdcnwrmpstatus-eng.pptx"}>
                    SDC NWRMP status (Eng){" "}
                </a>
                <a href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/03swissemnassypppforwatersectordonormeeting3.pptx"}>
                    Swiss Embassy PPT WSDM
                </a>
                <a href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/04gizpptwater-sector-donor-meeting.pptx"}>
                    GIZ PPT WSDM 4 <i className="fa fa-download"></i>
                </a>
                <a href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/05afd-for-water-sector-donor-meeting.pptx"}>
                    AFD WSDM
                </a>
                <a href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/06eu-ppp-for-water-sector-donor-meeting-220530.pptx"}>
                    EU PPT WSDM{" "}
                </a>
                <a
                    target="_blank"
                    href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/07adb-water-sector-donor-meeting-final.pdf"}
                >
                    ADB WSDM FINAL{" "}
                </a>
                <a href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/agenda.docx"}>Kun tartibi (uzb/eng)</a>
                <a href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/lopwsdm.docx"}>LOP_WSDPCM</a>
                <a href={process.env.REACT_APP_ASSETS_URL+"/uploads/files/coordination/final-to-circulate-water-sector-development-partners-meeting-minutes31-may-2022feedback-brx.docx"}>Yig'ilish protokoli</a>
            </div>

            <div><h2>WSDM dan video</h2></div>
            <br/>
            <div>

                <iframe width="100%" height="400" src="https://www.youtube.com/embed/Q3HOUijuAQw" title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
            <br/><br/>
            <div><h2>Taraqqiyot bo'yicha hamkorlarning loyihalari</h2></div>
            <br/>
            <ul>
                <li>
                    <h3>
                        <a target="_blank" href="https://projects.worldbank.org/en/projects-operations/projects-list?lang=en&countrycode_exact=UZ&os=0">
                            Jahon Bankining O'zbekistondagi loyihalari
                        </a>
                    </h3>
                </li>
                <li>
                    <h3>
                        <a target="_blank" href="https://www.adb.org/projects/country/uzb">
                            Osiyo Taraqqiyot Bankining O'zbekistondagi loyihalari
                        </a>
                    </h3>
                </li>
                <li>
                    <h3>
                        <a target="_blank" href="https://www.undp.org/uzbekistan/projects">
                            BMTTDning O'zbekistondagi loyihalari
                        </a>
                    </h3>
                </li>
                <li>
                    <h3>
                        <a target="_blank" href="https://www.eeas.europa.eu/filter-page/projects_en?fulltext=Uzbekistan&start_from=&start_to=" rel="noopener noreferrer">
                            Yevropa Ittifoqining Markaziy Osiyodagi loyihalari
                        </a>
                    </h3>
                </li>
                <li>
                    <h3>
                        <a target="_blank" href="https://www.eda.admin.ch/deza/en/home/countries/central-asia.html" rel="noopener noreferrer">
                            Shveytsariya taraqqiyot va hamkorlik agentligining Markaziy Osiyo ma'lumotlar ombori
                        </a>
                    </h3>
                </li>
                <li>
                    <h3>
                        <a target="_blank" href="https://www.giz.de/en/worldwide/364.html" rel="noopener noreferrer">
                            Germaniya xalqaro hamkorlik jamiyatining O'zbekistondagi loyiha va dasturlari
                        </a>
                    </h3>
                </li>
                <li>
                    <h3>
                        <a target="_blank" href="https://www.afd.fr/en/page-region-pays/uzbekistan" rel="noopener noreferrer">
                            Frantsiya taraqqiyot agentligining O'zbekistondagi loyihalari
                        </a>
                    </h3>
                </li>
            </ul>
            <br/><br/><br/>
        </Wrapper>
    );
}
