import "./header.scss";
import "./header_response.scss";
import { Drawer } from "antd";
import "antd/dist/antd.css";
import Close from "../../assets/icons/delete.png";
import React, {  useRef } from "react";

import { NavLink, Link } from "react-router-dom";
import { HeaderMenu } from "../index";

import { Forma } from "../../components/index";
import User from "../../assets/icons/user.png";
import Search from "../../assets/icons/search.png";
import { useEffect, useState } from "react";
import Humb from "../../assets/icons/humbu.png";
import { useDispatch, useSelector } from "react-redux";
import { set_lang, set_title } from "../../redux/action";

import { request } from "../../api/request";
import LogOut from "../../assets/icons/logout.png";
import UserIcon from "../../assets/icons/user (1).png";
import axios from "axios";

const Header = ({ overlay }) => {
  const ref = useRef();
  const lang = useSelector((state) => state.data.lang);


  const hasToken = JSON.parse(window.localStorage.getItem("USER_CREDENTIALS"))

  const [search, setSearch] = useState(false);
  const logo = useSelector((state) => state.data.app);
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const [visibleTwo, setVisibleTwo] = useState(false);
  const showDrawerTwo = () => {
    setVisibleTwo(true);
  };
  const onCloseTwo = () => {
    setVisibleTwo(false);
  };
  const dispatch = useDispatch();
  const onChange = (lang) => {
    dispatch(set_lang(lang));
    window.localStorage.setItem("lang", lang);
    setBold(lang);
  };
  const [bold, setBold] = useState(window.localStorage.getItem("lang"));

  const [menu, setMenu] = useState({
    isFetched: false,
    data: {},
    error: null,
  });

  const [searchData, setSearchData] = useState({
    isFetched: false,
    data: {},
    error: null,
  });

  useEffect(() => {
    request
      .get(`/menu/all?lang=${lang}`, {
        headers: {
          "api-token": "p12378",
        },
      })

      .then((res) => {
        setMenu({
          isFetched: true,
          data: res.data.data,
          error: false,
        });
      })

      .catch((err) => {
        setMenu({
          isFetched: true,
          data: [],
          error: err,
        });
      });
  }, [lang]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  const GET_DATA = (query) => {
    request
      .get(`/content/search/`, {
        headers: {
          "api-token": "p12378",
        },
        params: {
          lang,
          text: query,
        },
      })

      .then((res) => {
        setSearchData({
          isFetched: true,
          data: res.data.data,
          error: false,
        });
      })

      .catch((err) => {
        setSearchData({
          isFetched: true,
          data: [],
          error: err,
        });
      });
     
  };
  const someFunc = () => {
    setSearch(false);
    setSearchData(searchData.data = {});
    ref.current.value = ''
  };
  const token = JSON.parse(window.localStorage.getItem("USER_CREDENTIALS"));

  const signOut = () => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/account/signout`,
      headers: {
        "api-token": "p12378",
        "X-Auth-Token": token.token,
        "X-Auth-Signature": token.signature,
      },
    };
    axios(config)
      .then((res) => {
        
        window.localStorage.removeItem("USER_CREDENTIALS");
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="header">
      <div className="test-flex" style={{display: 'none'}}>
        <h1 className="test-mode">Sayt test rejimida ishlamoqda !</h1>
        <h1 className="test-mode hidden-xs hidden-s">Sayt test rejimida ishlamoqda !</h1>
        <h1 className="test-mode hidden-xs hidden-s">Sayt test rejimida ishlamoqda !</h1>
      </div>
      <div className={`search-component ${search ? "active" : ""}`}>
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault(GET_DATA(ref.current.value));
          }}
        >
          <input className="input-search" type="text" name="" id="" ref={ref} />
        </form>
        <button onClick={() => someFunc()} className="close-btn">
          <img src={Close} alt="" />
        </button>

        <div className="result-cards container">
          <ul className="search-ul">
            {searchData.isFetched ? (
              searchData.data &&
              searchData.data.map((el, index) => (
                <li className="search-li" key={index}>
                  <Link
                    onClick={() => someFunc()}
                    to={`/single/${el.type}/${el.id}`}
                    className="search-link"
                  >
                    {el.title}
                  </Link>
                </li>
              ))
            ) : (
              <></>
            )}
          </ul>
        </div>
      </div>

      <div className={`overlay active ${overlay ? "active" : ""}`}></div>
      <div className="header-inner container">
        <div className="header-middle">
          <div className="logo">
            <Link to="/" className="logo-link">
              {logo && logo.status === 1 ? (
                logo.data &&
                logo.data.map((el, index) =>
                  el.settings_key === "site_logo" ? (
                    <img key={index} src={el.settings_value} alt="" />
                  ) : null
                )
              ) : (
                <></>
              )}
            </Link>
            <div>
              {logo && logo.status === 1 ? (
                logo.data &&
                logo.data.map((el, index) =>
                  el.settings_key === "site_name" ? (
                    <h1 key={index}>{el.settings_value}</h1>
                  ) : null
                )
              ) : (
                <></>
              )}
              <a
                className="old-site"
                href="http://www.wsrc.uz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {/*{lang === "uz"*/}
                {/*  ? "Saytning eski talqini"*/}
                {/*  : lang === "ru"*/}
                {/*    ? "Старая интерпретация сайта"*/}
                {/*    : lang === "en"*/}
                {/*      ? "An old interpretation of the site"*/}
                {/*      : " "}*/}
              </a>
            </div>
          </div>
          <div className="languages">
            <ul>
              {/*<li>*/}
              {/*  <button*/}
              {/*    className={`${bold === "uz" ? "active" : ""}`}*/}
              {/*    onClick={(e) => {*/}
              {/*      onChange("uz");*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    Uzb*/}
              {/*  </button>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <button*/}
              {/*    className={`${bold === "ru" ? "active" : ""}`}*/}
              {/*    onClick={() => {*/}
              {/*      onChange("ru");*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    Pyc*/}
              {/*  </button>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <button*/}
              {/*    className={`${bold === "en" ? "active" : ""}`}*/}
              {/*    onClick={() => {*/}
              {/*      onChange("en");*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    Eng*/}
              {/*  </button>*/}
              {/*</li>*/}
            </ul>
          </div>
          <div className="phone-number">
            {logo && logo.status === 1 ? (
              logo.data.map((el, index) =>
                el.settings_key === "mobile_number" ? (
                  <a key={index} href={`tel: ${el.settings_value}`}>{el.settings_value}</a>
                ) : null
              )
            ) : (
              <h1>Loading ...</h1>
            )}
          </div>
          <div className="log-searc">
            {/*{hasToken ? (*/}
            {/*  <>*/}
            {/*    <div className="user-contact">*/}
            {/*      <span>{hasToken.fullName}</span>*/}
            {/*      <img className="user-icon" src={UserIcon} alt="" />*/}
            {/*    </div>*/}
            {/*    <button onClick={() => signOut()} className="log-out">*/}
            {/*      <img src={LogOut} alt="" />*/}
            {/*    </button>*/}
            {/*  </>*/}
            {/*) : (*/}
              <>
                {/*<button onClick={showDrawerTwo} className="user">*/}
                {/*  <img src={User} alt="" />*/}
                {/*</button>*/}
                <a href="http://wsrc-admin.wsrc.uz/" target="_blank" className="user">
                  <img src={User} alt="" />
                </a>
              </>
            {/*)}*/}
            <div className="form">

              <Drawer
                visible={visibleTwo}
                placement={"right"}
                onClose={onCloseTwo}
                closable={false}
                width={"300px"}
              >
                <div className="image-logo">
                  {logo && logo.status === 1 ? (
                    logo.data &&
                    logo.data.map((el, index) =>
                      el.settings_key === "site_logo" ? (
                        <img key={index} src={el.settings_value} alt="" />
                      ) : null  
                    )
                  ) : (
                    <></>
                  )}
                </div>
                <Forma />
              </Drawer>
            </div>
            <button
              onClick={() => {
                setSearch(true);
              }}
              className="search"
            >
              <img src={Search} alt="" />
            </button>
          </div>
          <div className="humb-btn">
            <div className="open-humb">
              <img onClick={showDrawer} src={Humb} alt="" />
            </div>
            <div className="drawerContent">
              <Drawer
                visible={visible}
                placement={"right"}
                onClose={onClose}
                closable={false}
                width={"80%"}
              >
                <HeaderMenu />
              </Drawer>
            </div>
          </div>
        </div>
        <div className="header-bottom">
          {
            menu.isFetched ? (
              menu.data.map((el, index) => (
                <ul className="dropdown" key={index}>
                  {
                    el.group_key === "main-menu" ? (
                      el.child.map((el, index) => (
                        <li key={index}>
                          {el.data.link_target === "_blank" ? (
                            <a
                              href={el.data.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={`drop-link_name ${el.child && el.child.length !== 0 ? "" : "activeTwo"
                                }`}
                            >
                              {el.data.name}
                              
                            </a>
                          ) : (
                            <NavLink
                            key={index}
                              to={`${el.data.link}`}
                              exact
                              className={`drop-link_name ${el.child && el.child.length !== 0 ? "" : "activeTwo"
                                }`}
                            >
                              {el.data.name}
                              
                            </NavLink>
                          )}
                          <ul>
                            {el.child &&
                              el.child.map((item, index) => (
                                <li key={index}>
                                  {
                                    item.data.link_target === "_blank" ? (
                                      <a
                                        key={index}
                                        href={item.data.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="forward-link"
                                      >
                                        {item.data.name}
                                        {item.data.id}
                                      </a>
                                    ) : (
                                      <Link key={index} onClick={() => dispatch(set_title(item.data.name))} to={item.data.link} className="forward-link">
                                        {item.data.name}
                                  
                                      </Link>
                                    )

                                  }

                                  <ul>
                                    {item.child &&
                                      item.child.map((item, index) => (
                                        <li key={index}>
                                          {
                                            item.data.link_target === "_blank" ? (
                                              <a
                                                key={index}
                                                href={item.data.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="forward-link"
                                              >
                                                {item.data.name}
                                              </a>
                                            ) : (
                                              <Link to={item.data.link} className="forward-link">
                                                {item.data.name}
                                              </Link>
                                            )

                                          }

                                          <ul>
                                            {item.child &&
                                              item.child.map((item, index) => (
                                                <li key={index}>
                                                  {
                                                    item.data.link_target === "_blank" ? (
                                                      <a
                                                        key={index}
                                                        href={item.data.link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="forward-link"
                                                      >
                                                        {item.data.name}
                                                      </a>
                                                    ) : (
                                                      <Link to={item.data.link} className="forward-link">
                                                        {item.data.name}
                                                      </Link>
                                                    )

                                                  }

                                                  <ul>
                                                    {

                                                    }
                                                  </ul>
                                                </li>
                                              ))}
                                          </ul>
                                        </li>
                                      ))}
                                  </ul>
                                </li>
                              ))}
                          </ul>
                        </li>
                      ))
                    ) : (
                      null
                    )
                  }
                </ul>
              ))
            ) : (
              null
            )
          }
        </div>
      </div>
    </div>
  );
};

export default Header;



