import { Helmet } from "react-helmet"
import { useState, useEffect } from "react"
import { request } from "../../api/request"
import { useSelector } from "react-redux"
import React from "react"
const Helm = ({ id})=>{
    const [helm, setHelm] = useState({
        isFetched:false,
        data:{},
        error:null
    })

    const lang = useSelector(state => state.data.lang)

    useEffect(()=>{
        request
        .get(`/content/one/page?id=${id}&lang=${lang}`,{
            headers:{
                "api-token":"p12378"
            }
        })

        .then(res =>{
            setHelm({
                isFetched:true,
                data:res.data.data,
                error:false
            })
        })
        .catch(err =>{
            setHelm({
                isFetched:true,
                data:[],
                error:err
            })
        })
    }, [id, lang])

    return (
        <>
            {
                helm.isFetched ? (
                    <Helmet>
                        <title>
                            {
                                `
                                    WSRC - ${helm.data.title}
                                `
                            }
                        </title>
                    </Helmet>
                ):(
                    <></>
                )
            }
        </>
    )
}

export default Helm